import { Carousel } from "react-bootstrap";
import image from '../img/banners/mainbanner.jpg';
import imagemobile from '../img/banners/mobilebanner1.jpg';
import classes from './MainSlider.module.css';
import { Slider } from "../Helpers/const";

const MainSlider = () => {

    return (
      <div>
        <Carousel>
          {Slider.map(item=>
            <Carousel.Item key={Math.random()} className={classes["main-slider"]}>
              <img
              className={classes["desktop-display"]}
              src={process.env.PUBLIC_URL + item.img} 
              alt={item.alt}/>
              <img
              className={classes["mobile-display"]}
              src={item.img}
              alt={item.alt}/>
              {/* <Carousel.Caption className={classes["custom-caption"]}>
                <h1>{item.title}</h1>
                <h3>{item.subtitle}</h3>
                <p>{item.caption}</p>
                <div className={classes.button}>Lorem ipsum</div>
              </Carousel.Caption> */}
          </Carousel.Item>
          )}
        </Carousel>
      </div>
    )
};

export default MainSlider;