import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {NavLink} from 'react-router-dom';
import { MenuCategories } from "../Helpers/const";
import { NavDropdown } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Input from 'react-bootstrap/InputGroup';
import logo from '../img/logo/logo.png';
import search from '../img/elements/search.png';
import heart from '../img/elements/heart.png';
import cart from '../img/elements/shopping-bag.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classes from './NavbarMenu.module.css';
import "bootstrap/dist/css/bootstrap.css";
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

const NavbarMenu = () => {
    const [isShown, setIsShown] = useState(false);
    const [categoryData, setCategoryData] = useState([]);

    return (
        <div className={classes["navbar"]}>
            <div className={classes["top-header-links"]}>
                <div className={classes["header-links-holder"]}>
                    <ul>
                        <li>medigrafca@gmail.com</li>
                        <li><span>l</span></li>
                        <li>+381 64 613 94 68</li>
                        <li><span>l</span></li>
                        <li>Aleksandra Savića 42, Srbija</li>
                    </ul>
                </div>
            </div>
            <div className={classes["top-header"]}>
                <Container fluid className={classes.custom}>
                    <Row>
                        <Col xs={5} sm={5} md={4} xl={2} lg={2} className={`${classes['col-holder']} ${classes['logo-holder']}`}>
                            <div className={classes["image-holder"]}>
                                <img
                                    src={logo}
                                    className="d-inline-block align-top w-100"
                                    alt="React"
                                />
                            </div>
                        </Col>
                        <Col xl={6} lg={6} className={classes["nav-holder"]}>
                        <Navbar expand="lg" className={classes["navbar-menu"]} style={{"position": "inherit"}}>
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className={classes.holder}>
                                    <Nav className={classes.nav} style={{"position": "inherit"}}>
                                        <ul className={classes["nav-list"]}>
                                            <li><NavLink to="/" className={classes.navlink}>Naslovna</NavLink></li>
                                            <li className={classes["dropdown-hover"]}>
                                                <Nav.Link
                                                    onMouseEnter={() => setIsShown(true)}
                                                    className={`${classes["dropdown-holder"]} ${classes.navlink} ${classes.category}`}>
                                                    Kategorije
                                                </Nav.Link>
                                                <Container 
                                                onMouseLeave={() => setIsShown(false)}          
                                                fluid className={ isShown ? classes.animation : classes.word}>
                                                    <Row className={classes["nav-submenu"]}>
                                                        <Col xs={1}></Col>
                                                        {MenuCategories.map(item=>
                                                        <Col key={Math.random()} className={classes["nav-submenu-item"]}>
                                                            <div className={classes["submenu-item-holder"]}>
                                                                <div className={classes["image-holder"]}>
                                                                    <img
                                                                        src={"/"+process.env.PUBLIC_URL + item.img} 
                                                                        className="d-inline-block"
                                                                        alt="React"
                                                                    />
                                                                </div>
                                                                <h5>{item.name}</h5>
                                                                <ul className={classes["product-list"]}>
                                                                {item.children.map(child=>
                                                                    <li key={Math.random()}><a href="#">{child.name}</a></li>)}
                                                                </ul>
                                                            </div>
                                                        </Col>)}
                                                        <Col xs={1}></Col>
                                                    </Row>
                                                 </Container>
                                            </li>
                                            <li><NavLink to="/" className={classes.navlink}>O nama</NavLink></li>
                                            <li><NavLink to="/" className={classes.navlink}>Biznis oglasi</NavLink></li>
                                            <li><NavLink to="/" className={classes.navlink}>Galerija</NavLink></li>
                                            <li><NavLink to="/" className={classes.navlink}>Kontakt</NavLink></li>
                                        </ul>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                        </Col>
                        <Col xl={3} lg={3} md={4} className={`${classes['col-holder']} ${classes['mobile-none']}`}>
                                <div className={classes["search-holder"]}>
                                    <FontAwesomeIcon className={classes.search} icon={faMagnifyingGlass} />
                                    <input placeholder="Pretražite proizvode…" className={classes["search-input"]} type="text" id="docs-search-input" 
                                    mplete="off" spellCheck="false" role="combobox" aria-autocomplete="list" aria-expanded="false" aria-label="search input" aria-owns="algolia-autoComplete-listbox-16"/>
                                </div>
                        </Col>
                        <Col xs={4} md={2} sm={3} xl={1} lg={1} className={classes["col-holder"]}>
                            <div className={classes["icons-holder"]}>
                                <div>
                                    <ul className={classes["icons-list"]}>
                                        <li> 
                                            <a href="#">
                                                <img
                                                src={heart}
                                                className="d-inline-block align-top w-100"
                                                alt="React"/>
                                            </a>
                                            <span>0</span>
                                        </li>
                                        <li> 
                                            <a href="#">
                                                <img
                                                src={cart}
                                                className="d-inline-block align-top w-100"
                                                alt="React"/>
                                            </a>
                                            <span>0</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                        <Col md={1} sm={2} xs={2} className={classes["mobile-search"]}>
                            <div className={classes["search-holder-mobile"]}>
                                    <img
                                    src={search}
                                    className="d-inline-block align-top w-100"
                                    alt="React"
                                    />
                            </div>
                        </Col>
                        <Col xs={1} sm={1} md={1} className={classes["mobile-nav-holder"]}>
                            <div className={classes["mobile-nav"]}>
                                <Navbar bg="light" expand="lg" className={classes["mobile-navbar"]}>
                                    <Container>
                                        <Navbar.Toggle aria-controls="basic-navbar-nav" className={classes["toggle-button"]}/>
                                        <Navbar.Collapse id="basic-navbar-nav" className={classes.navcollapse}>
                                        <Nav className="me-auto" className={classes["mobile-nav"]}>
                                            <Nav.Link href="#home" className={classes["mobile-nav-link"]}>Naslovna</Nav.Link>
                                            <NavDropdown title="Kategorije" id="basic-nav-dropdown" className={classes["mobile-nav-dropdown"]}>
                                                <NavDropdown.Item href="#action/3.1">
                                                {categoryData.map(item=>
                                                    <div xs={1} key={Math.random()} className={classes["nav-submenu-item"]}>
                                                        <div className={classes["submenu-item-holder"]}>
                                                                <div className={classes["image-holder"]}>
                                                                    <img
                                                                        src={process.env.PUBLIC_URL + item.img} 
                                                                        className="d-inline-block"
                                                                        alt="React"
                                                                    />
                                                                </div>
                                                                <h5>{item.name}</h5>
                                                                <ul className={classes["product-list"]}>
                                                                {item.children.map(child=>
                                                                    <li key={Math.random()}><a href="#">{child.name}</a></li>)}
                                                                </ul>
                                                            </div>
                                                    </div>)}
                                                </NavDropdown.Item>
                                            </NavDropdown>
                                            <Nav.Link href="#about" className={classes["mobile-nav-link"]}>O nama</Nav.Link>
                                            <Nav.Link href="#bussiness" className={classes["mobile-nav-link"]} >Biznis oglasi</Nav.Link>
                                            <Nav.Link href="#link" className={classes["mobile-nav-link"]}>Galerija</Nav.Link>
                                            <Nav.Link href="#contact">Kontakt</Nav.Link>
                                        </Nav>
                                        </Navbar.Collapse>
                                    </Container>
                                </Navbar>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    )
};

export default NavbarMenu;