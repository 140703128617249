import React, {useState} from "react";
import validator from 'validator';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import nl from '../img/elements/newsletter.jpg';
import NewsletterModal from "./NewsletterModal";
import classes from './Newsletter.module.css';

const Newsletter = () => {
    const [emailError, setEmailError] = useState('');
    const [currentInput, setCurrentInput] = useState('');
    const [showModal, setShowModal] = useState(false);
    const onSendClickHandler = () => {
       if(currentInput !== '' && emailError === '')
       {
           setShowModal(true)
       }
       setCurrentInput('');
    }
  const validateEmail = (e) => {
    setCurrentInput(e.target.value);
    let email = e.target.value;
    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Unesite validan e-mail!')
    }
  }
    return(
        <div className={classes["newsletter-holder"]}>
            <Container>
                <NewsletterModal showModal={showModal} handleClose={()=>setShowModal(false)}/>
                <Row>
                    <Col xl={4} lg={4} md={2} sm={12} xs={12}>
                        <div className={classes["image-holder"]}>
                            <img
                                src={nl}
                                className="d-inline-block align-top w-100 img-fluid"
                                alt="React"
                            />
                        </div>
                    </Col>
                    <Col xl={8} lg={8} md={10} sm={12} xs={12}>
                        <h5>Prijavite se na<span> newsletter</span></h5>
                        <p>Prijavite se i saznajte sve o akcijama, specijalnim ponudama i svim aktuelnostima.</p>
                        <div className={classes["nl-elements-holder"]}>
                            <div className={classes["email-holder"]}>
                                <input placeholder="Vaš E-mail" value={currentInput} onChange={(e) => validateEmail(e)} className={classes["email-input"]} type="text" id="docs-email-input" 
                                mplete="off" spellCheck="false" role="combobox" aria-autocomplete="list" aria-expanded="false" aria-label="email input" aria-owns="algolia-autoComplete-listbox-16"/>
                                <h3 className={classes["nl-error"]}>{emailError}</h3>
                            </div>
                            <button type='button' onClick={onSendClickHandler} className={classes.button}>Pošaljite</button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Newsletter;