import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import clock from '../img/elements/clock.png';
import rocket from '../img/elements/rocket.png';
import price from '../img/elements/price.png';
import update from '../img/elements/update.png';
import classes from './Benefits.module.css';
import {Slider} from '../Helpers/const';

const Benefits = () => {
    const testFetch = () => {
        fetch('https://test-base-67252-default-rtdb.europe-west1.firebasedatabase.app/kategorije.json', {method:'PUT', body:JSON.stringify(Slider)}).then(
            response => response.json()
        )
    };
    return (
        <div className={classes["benefits-holder"]}>
            <Container fluid>
                <Row>
                    <Col xl={3} sm={12} md={4} lg={4} className={classes["element-wrapper"]}  data-aos="fade-up" data-aos-duration="1000">
                        <div className={classes["image-holder"]}>
                            <img
                                src={clock}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                        <div className={classes["text-holder"]}>
                            <h5>Konkurentne cene</h5>
                            <div className={classes.line}></div>
                            <p>Uz izuzetno visok nivo efikasnosti izrade proizvoda posedujemo<span> veoma pristupačne cene</span>.</p>
                        </div>
                    </Col>
                    <Col xl={3} sm={12} md={4} lg={4} className={classes["element-wrapper"]} data-aos="fade-up" data-aos-duration="2300">
                        <div className={classes["image-holder"]}>
                            <img
                                src={rocket}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                        <div className={classes["text-holder"]}>
                            <h5>Profesionalnost zaposlenih</h5>
                            <div className={classes.line}></div>
                            <p>Naši zaposleni se trude da zadovoljavaju zahteve <span>svih naših klijenata u najkraćem mogućem roku </span>.</p>
                        </div>
                    </Col>
                    <Col xl={3} sm={12} md={4} lg={4} className={classes["element-wrapper"]} data-aos="fade-up" data-aos-duration="1800">
                        <div className={classes["image-holder"]}>
                            <img
                                src={price}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                        <div className={classes["text-holder"]}>
                            <h5 onClick={testFetch}>Brza isporuka</h5>
                            <div className={classes.line}></div>
                            <p>Optimalno postavljena proizvodnja nam omogućava <span>brzu reakciju i kratke rokove realizacije </span>.</p>
                        </div>
                    </Col>
                    <Col xl={3} sm={12} md={4} lg={4} className={classes["element-wrapper"]} data-aos="fade-up" data-aos-duration="2300">
                        <div className={classes["image-holder"]}>
                            <img
                                src={update}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                        <div className={classes["text-holder"]}>
                            <h5>Podrška</h5>
                            <div className={classes.line}></div>
                            <p>Imate pitanje? Za sve potrebne informacije, <span>pozovite naše prodajne savetnike</span>.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Benefits;