import React from 'react';
import MainSlider from '../Components/MainSlider';
import Benefits from '../Components/Benefits.js';
import Categories from '../Components/Categories';
import ActionBanners from '../Components/ActionBanners.js';
import Newsletter from '../Components/Newsletter';
import ProductList from '../Components/ProductList';
import classes from './Homepage.module.css';

const Homepage = () => {
    return (
        <div className={classes.homepage}>
            <MainSlider></MainSlider>
            <Benefits></Benefits>
            <ActionBanners></ActionBanners>
            <Categories></Categories>
            <ProductList></ProductList>
            <Newsletter></Newsletter>
        </div>
    )
};

export default Homepage;