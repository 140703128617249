import OwlCarousel from 'react-owl-carousel';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import React,{Component} from 'react';  
import 'owl.carousel/dist/assets/owl.carousel.css';  
import 'owl.carousel/dist/assets/owl.theme.default.css';  
import './Owl.css';  
const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};

export class Owldemo1 extends Component {  
    render()  
    {  
      return (  
        <div className='owl-holder'>  
            <div className='container-fluid' >            
                <OwlCarousel items={3}  
                    className="owl-theme"  
                    loop  
                    responsiveClass={true} autoplay ={true}
                    {...options} 
                    nav  
                    margin={8} >  
                    <div ><img  className="img h-100" src= {process.env.PUBLIC_URL+'/assets/img/img1.jpg'}/></div>  
                    <div><img  className="img h-100" src= {process.env.PUBLIC_URL+'/assets/img/img2.jpg'}/></div>  
                    <div><img  className="img h-100" src= {process.env.PUBLIC_URL+'/assets/img/img3.jpg'}/></div>  
                    <div><img  className="img h-100" src= {process.env.PUBLIC_URL+'/assets/img/img1.jpg'}/></div>  
                    <div><img className="img h-100" src= {process.env.PUBLIC_URL+'/assets/img/img2.jpg'}/></div>  
                    <div><img className="img h-100" src= {process.env.PUBLIC_URL+'/assets/img/img3.jpg'}/></div>  
                </OwlCarousel>  
            </div>  
        </div>  
      )  
    }  
  }  
    

export default Owldemo1;