import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Banners } from "../Helpers/const";
import bigbanner from '../img/banners/bigbanner.jpg';
import middlebannertop from '../img/banners/middlebannertop.jpg';
import middlebannerbottom from '../img/banners/middlebannerbottom.jpg';
import smallbanner from '../img/banners/minibanner.jpg';
import classes from './ActionBanners.module.css';

const ActionBanners = () => {

    return (
        <div className={classes["banners-holder"]}>
            <Container fluid>
            {Banners.map(item=>
                <Row key={Math.random()}>
                    <Col xl={4} lg={4} md={8} sm={12} xs={12} className={classes["big-banner"]}>
                        <div className={classes["image-holder"]}>
                            <img
                                src={bigbanner}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={8} sm={12} xs={12} className={classes["middle-banners"]}>
                        <div className={classes["image-holder"]}>
                            <img
                                src={middlebannertop}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                        <div className={classes["image-holder"]}>
                            <img
                                src={middlebannerbottom}
                                className="d-inline-block align-top w-100"
                                alt="React"
                            />
                        </div>
                    </Col>
                    <Col xl={4} lg={4} md={4} sm={12} xs={12} className={classes["small-banner"]}>
                        <div className={classes["image-holder"]}>
                            <img
                                src={smallbanner}
                                className="d-inline-block align-top w-100 h-100"
                                alt="React"
                            />
                        </div>
                    </Col>
                </Row> 
                )}
            </Container>
        </div>
    )
};

export default ActionBanners;