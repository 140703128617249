import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MainCategories } from "../Helpers/const";
import cat1 from '../img/elements/cat1.jpg';
import cat2 from '../img/elements/cat2.jpg';
import cat3 from '../img/elements/cat3.jpg';
import classes from './Categories.module.css';

const Categories = () => {
    return (
        <div className={classes["categories"]}>
            <Container fluid>
                <Row className={classes["categories-header"]}>
                    <Col xl={2} lg={2} md={2} sm={12} xs={12} className={classes["title-holder"]}>
                        <h5>Kategorije</h5>
                    </Col>
                    <Col xl={10} lg={10} md={10} sm={12} xs={12} className={classes["button-holder"]}>
                        <div className={classes["categories-header-holder"]}>
                            <div className={classes.headline}></div>
                            <div className={classes.button}>Više</div>
                        </div>
                    </Col>
                </Row>
                
                <Row className={classes["categories-body-holder"]}>
                {MainCategories.map(item=>
                    <Col key={Math.random()} xl={2} lg={2} md={4} sm={12} xs={12} className={classes["categories-col"]}>
                        <a href={item.catlink}>
                            <div className={classes["categories-holder"]}>
                                <div className={`${classes['image-holder']} ${classes.box}`}>
                                    <img
                                       src={process.env.PUBLIC_URL + item.catimage} 
                                        className="d-inline-block align-top w-100 img-fluid"
                                        alt="React"
                                    />
                                    <div className={classes["box-content"]}>
                                        <button type="button" className={classes["view-more"]}> Pogledajte više</button>
                                    </div>
                                </div>
                            </div>
                            <div className={classes["text-holder"]}>
                                    <p>{item.catname}</p>
                            </div>
                        </a>
                    </Col>
                     )}
                </Row>
               
            </Container>
        </div>
    )
};

export default Categories;