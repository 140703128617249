import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from 'react-router-dom';
import { MainProducts } from "../Helpers/const";
import product1 from '../img/products/product1.jpg';
import product2 from '../img/products/product2.jpg';
import product3 from '../img/products/product3.jpg';
import product4 from '../img/products/product4.jpg';
import wishlist from '../img/elements/wishlist.png';
import cart from '../img/elements/cart.png';
import classes from './ProductList.module.css';

const ProductList = () => {
    return(
        <div className={classes["product-list-holder"]}>
            <Container fluid>
                <Row className={classes["product-list-header"]}>
                    <Col xl={2} md={2} lg={2} sm={12} xs={12} className={classes["title-holder"]}>
                        <h5>Najprodavanije</h5>
                    </Col>
                    <Col xl={10} md={10} lg={10} sm={12} xs={12}>
                        <div className={classes["product-header-holder"]}>
                            <div className={classes.headline}></div>
                            <div className={classes.button}>Više</div>
                        </div>
                    </Col>
                </Row>
                <Row className={classes["product-body-holder"]}>
                {MainProducts.map(item=>
                    <Col xl={2} lg={2} md={3} sm={6} xs={6} key={Math.random()} className={classes["product-col"]}>
                        <div className={classes["product-holder"]}>
                            <div className={classes["image-holder"]}>
                            <img
                                src={process.env.PUBLIC_URL + item.prodimage} 
                                className="d-inline-block align-top w-100 img-fluid"
                                alt="React"
                            />
                            </div>
                            <div className={classes.badge}>
                                <p>NEW</p>
                            </div>
                            <div className={classes["info-holder"]}>
                            <div className={classes.colors}>
                                <div className={classes["color-holder-red"]}>
                                </div>
                                <div className={classes["color-holder-blue"]}>
                                </div>
                                <div className={classes["color-holder-orange"]}>
                                </div>
                            </div>
                                <p className={classes.code}>{item.code}</p>
                                <h5>{item.prodtitle}</h5>
                                <p>{item.proddesc}</p>
                                {/* <div className={classes.lagerlink}>
                                    <div className={classes.square}></div>
                                    <p className={classes.lager}>Na stanju</p>
                                </div> */}
                                <div className={classes["options-holder"]}>
                                    <div className={classes["buy-button"]}>
                                        <Link to ={`/proizvod/${item.prodlink}`}>
                                            Detaljnije
                                        </Link>
                                    </div>
                                    <div className={classes["add-to-cart"]}>
                                        <img
                                            src={cart} 
                                            className="d-inline-block align-top w-100 img-fluid"
                                            alt="React"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                )}
                </Row>
            </Container>
        </div>
    )
};

export default ProductList;