import React from "react";
import ReactDOM from "react-dom";
import Owldemo1 from '../Owldemo1';  
import OwlDemo from '../Owldemo';


import classes from './ProductImages.module.css';

const ProductImages = () => {
    return (
        <div className={classes["product-images-holder"]}>
           <Owldemo1></Owldemo1> 
           <div className={classes.colors}>
               <div className={classes.variant1}>
                   <div className={classes.color}></div>
                   <div className={classes["color-title"]}>
                       Crna
                   </div>
               </div>
               <div className={classes.variant2}>
                   <div className={classes.color}></div>
                   <div className={classes["color-title"]}>
                       Siva
                   </div>
               </div>
               <div className={classes.variant3}>
                   <div className={classes.color}></div>
                   <div className={classes["color-title"]}>
                       Oranž
                   </div>
               </div>
            </div> 
        </div>
    )
};

export default ProductImages;