import React from 'react';
import Container  from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import classes from "./Breadcrumbs.module.css";

const Breadcrumbs = () => {
    return (
        <Container fluid>
            <div className={classes["breadcrumbs-holder"]}>
                <ul>
                    <li><Link to={"#"} className={classes["breadcrumbs-link"]}>Homepage</Link></li>
                    <li><Link to={"#"} className={classes["breadcrumbs-link"]}>Products Page</Link></li>
                    <li><Link to={"#"} className={classes["breadcrumbs-link"]}>Product 243 Page</Link></li>
                </ul>
            </div>
        </Container>
    )
};

export default Breadcrumbs;