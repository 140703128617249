import React, { useEffect } from "react";
import Breadcrumbs from "../Components/Breadcrumbs";
import ProductImages from '../Components/ProductImages';
import ProductSpecification from '../Components/ProductSpecification';
import RelatedProducts from '../Components/RelatedProducts';
import classes from './ProductPage.module.css';

const ProductPage = () => {

    return (
            <div className={classes["product-page"]}>
                <Breadcrumbs>{/* <h1>Product page {params.productSlug}</h1> */}</Breadcrumbs>
                <ProductImages></ProductImages>
                <ProductSpecification></ProductSpecification>
                <RelatedProducts></RelatedProducts>
            </div>
    )
};

export default ProductPage;