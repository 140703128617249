

export const MenuCategories = [
    {
        "img" : "menucategory1.jpg",
        "id":"0",
        "name":"Kućni setovi",
        "children": [
            {
                "id":"01",
                "name":"Šolje"

            },
            {
                "id":"02",
                "name":"Sportske boce"

            },
            {
                "id":"03",
                "name":"Termosi"

            },
            {
                "id":"04",
                "name":"Kuhinjski pribor"

            },
            {
                "id":"05",
                "name":"Vinski setovi"

            },
            {
                "id":"06",
                "name":"Sport i zabava"

            },
            {
                "id":"07",
                "name":"Lepota"

            },
            {
                "id":"08",
                "name":"Zdravlje i zaštita"

            },
            {
                "id":"09",
                "name":"Upaljači"

            }
        ]
    },
    {
        "img" : "menucategory2.png",
        "id":"1",
        "name":"Tehnologija",
        "children": [
            {
                "id":"11",
                "name":"Pomoćne baterije"

            },
            {
                "id":"12",
                "name":"Audio uređaji"

            },
            {
                "id":"13",
                "name":"Bežični punjači"

            },
            {
                "id":"14",
                "name":"Auto oprema"

            },
            {
                "id":"15",
                "name":"USB kablovi za punjenje"

            },
            {
                "id":"16",
                "name":"Gedžeti"

            },
            {
                "id":"17",
                "name":"USB"

            }
        ]
    },
    {
        "img" : "menucategory3.jpg",
        "id":"2",
        "name":"Kancelarija",
        "children": [
            {
                "id":"21",
                "name":"Notesi & Agende"

            },
            {
                "id":"22",
                "name":"Kancelarija"

            },
            {
                "id":"23",
                "name":"Poklon kutije"

            },
            {
                "id":"24",
                "name":"Satovi"

            },
            {
                "id":"25",
                "name":"Promo pultevi i panoi"

            }
        ]
    },
    {
        "img" : "menucategory4.png",
        "id":"3",
        "name":"Olovke",
        "children": [
            {
                "id":"31",
                "name":"Plastične olovke"

            },
            {
                "id":"32",
                "name":"Metalne olovke"

            },
            {
                "id":"33",
                "name":"Setovi olovaka"

            },
            {
                "id":"34",
                "name":"Kutije za olovke"

            },
            {
                "id":"35",
                "name":"Drvene olovke"

            }
        ]
    },
    {
        "img" : "menucategory7.jpg",
        "id":"4",
        "name":"Privesci & Alati",
        "children": [
            {
                "id":"41",
                "name":"Privesci"

            },
            {
                "id":"42",
                "name":"Alati"

            }
        ]
    },
    {
        "img" : "menucategory6.jpg",
        "id":"5",
        "name":"Torbe & Putovanja",
        "children": [
            {
                "id":"51",
                "name":"Rančevi"

            },
            {
                "id":"52",
                "name":"Torbe"

            },
            {
                "id":"53",
                "name":"Putni program"

            },
            {
                "id":"54",
                "name":"Kese"

            },
            {
                "id":"52",
                "name":"Kišobrani"

            }
            
        ]
    },
    {
        "img" : "menucategory5.png",
        "id":"6",
        "name":"Tekstil",
        "children": [
            {
                "id":"61",
                "name":"Majice"

            },
            {
                "id":"62",
                "name":"Polo majice"

            },
            {
                "id":"63",
                "name":"Sportska oprema"

            },
            {
                "id":"64",
                "name":"Prsluci"

            },
            {
                "id":"65",
                "name":"Jakne"

            },
            {
                "id":"66",
                "name":"Poslovna oprema"

            },
            {
                "id":"67",
                "name":"Peškiri"

            },
            {
                "id":"68",
                "name":"Zaštitna oprema"

            },
            {
                "id":"69",
                "name":"Radna obuća"

            },
            {
                "id":"70",
                "name":"Kape"

            }
            
        ]
    }

]
export const Slider = [
    {
        "id": 1,
        "img": "mainbanner.jpg",
        "alt": "first-slide",
        "imgmobile": "http://localhost:3000/static/media/mobilebanner1.c7fd811ebfbd5ccda1d9.jpg",
        "title": "Medigraf",
        "subtitle": "Lorem ipsum",
        "caption": "Ako znate šta hoćete, tu smo za Vas, a i ako ne znate šta hoćete, tu smo da Vam pružimo prave informacije i da Vam pomognemo pri realizaciji istih."
    },
    {
        "id": 2,
        "img": "bannermain2.jpg",
        "alt": "second-slide",
        "imgmobile": "http://localhost:3000/static/media/mobilebanner2.e175e82b33c7946fa7b9.jpg",
        "title": "Medigraf",
        "subtitle": "Lorem ipsum",
        "caption": "Ako znate šta hoćete, tu smo za Vas, a i ako ne znate šta hoćete, tu smo da Vam pružimo prave informacije i da Vam pomognemo pri realizaciji istih."
    }
]

export const Banners = [
    {
        "id": 3,
        "bigbanner": "http://localhost:3000/static/media/bigbanner.b9ec32361dc6724d9663.jpg",
        "smallbanner": "http://localhost:3000/static/media/minibaner.62b71e498a35aa453834.jpg"
    }
]

export const MainCategories = [
    {
        "id": 4,
        "catimage": "cat1.jpg",
        "catname": "Kućni setovi",
        "catlink": "#"
    },
    {
        "id": 5,
        "catimage": "cat2.jpg",
        "catname": "Tehnologija",
        "catlink": "#"
    },
    {
        "id": 6,
        "catimage": "cat3.jpg",
        "catname": "Kancelarija",
        "catlink": "#"
    },
    {
        "id": 56,
        "catimage": "cat4.jpg",
        "catname": "Tekstil",
        "catlink": "#"
    },
    {
        "id": 57,
        "catimage": "cat5.jpg",
        "catname": "Privesci & alati",
        "catlink": "#"
    },
    {
        "id": 58,
        "catimage": "cat6.jpg",
        "catname": "Torbe & putovanja",
        "catlink": "#"
    }
]

export const MainProducts = [
    {
        "id":7,
        "prodimage": "products/product1.jpg",
        "prodtitle": "Magnote",
        "proddesc": "Portfolio A5, sa bežičnim punjačem, pomoćnom baterijom, 8000mA",
        "prodlink": "123",
        "code": "1225",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":8,
        "prodimage": "products/product2.jpg",
        "prodtitle": "Amber",
        "proddesc": "Humidor kutija za tompuse",
        "prodlink": "1234",
        "code": "6425",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":9,
        "prodimage": "products/product3.jpg",
        "prodtitle": "Source",
        "proddesc": "Držač i bežični punjač mobilnih uređaja sa magnetom za automobil",
        "prodlink": "25",
        "code": "8975",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":10,
        "prodimage": "products/product4.jpg",
        "prodtitle": "Fluid Mini",
        "proddesc": "Termos 260ml",
        "prodlink": "26",
        "code": "9836",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":11,
        "prodimage": "products/product5.jpg",
        "prodtitle": "Noise",
        "proddesc": "Bluetooth zvučnik",
        "prodlink": "34",
        "code": "6045",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":12,
        "prodimage": "products/product6.jpg",
        "prodtitle": "Mondo plus",
        "proddesc": "Notes A5",
        "prodlink": "46",
        "code": "4598",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    }   

]
export const ProductItems = [
    {
        "img" : "assets/img/img1.jpg"
    },
    {
        "img" : "assets/img/img2.jpg"
    },
    {
        "img" : "assets/img/img3.jpg"
    }
]

export const Specification = [
    {
        "spec-title": "34.470.10 BIZZ - Poslovni ranac",
        "spec-code": "34.470.10",
        "spec-model": "BIZZ",
        "spec-desc": "Poslovni ranac sa jednim glavnim odeljkom, naloženi odeljci za tablet i laptop veličine ekrana 15 inca, dva džepa sa rajsferšlusom napred i dva mrežasta džepa sa strane",
        "spec-brand": "Epluribus",
        "spec-color": "crna",
        "spec-dimensions": "45 x 30 x 15 cm",
        "spec-package": "10/1",
        "spec-material": "Poliester",
        "spec-quantity": "10 komada",
        "spec-box-dimensions": "0.595 x 0.375 x 0.475 m",
        "spec-box-weight": "9.68 kg",
        "spec-box-volume": "0.106 m3",
        "spec-made-in": "Kina",
        "spec-tariff": "420292910000"
    }
]
export const RelatedProductsList = [
    {
        "id":67,
        "relimage": "products/product1.jpg",
        "prodtitle": "Magnote",
        "proddesc": "Portfolio A5, sa bežičnim punjačem, pomoćnom baterijom, 8000mA",
        "prodlink": "123",
        "code": "1225",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":68,
        "relimage": "products/product2.jpg",
        "prodtitle": "Amber",
        "proddesc": "Humidor kutija za tompuse",
        "prodlink": "1234",
        "code": "6425",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":69,
        "relimage": "products/product3.jpg",
        "prodtitle": "Source",
        "proddesc": "Držač i bežični punjač mobilnih uređaja sa magnetom za automobil",
        "prodlink": "25",
        "code": "8975",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":70,
        "relimage": "products/product4.jpg",
        "prodtitle": "Fluid Mini",
        "proddesc": "Termos 260ml",
        "prodlink": "26",
        "code": "9836",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":71,
        "relimage": "products/product5.jpg",
        "prodtitle": "Noise",
        "proddesc": "Bluetooth zvučnik",
        "prodlink": "34",
        "code": "6045",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    },
    {
        "id":72,
        "relimage": "products/product6.jpg",
        "prodtitle": "Mondo Plus",
        "proddesc": "Notes A5",
        "prodlink": "46",
        "code": "4598",
        "lager": "Prikaz zaliha",
        "lagerlink": "#"
    }
    
]