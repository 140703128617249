import Modal from 'react-bootstrap/Modal';
import check from '../img/elements/check.png';
import classes from './NewsletterModal.module.css';
const NewsletterModal = ({showModal, handleClose}) => {
    return (
            <Modal className={classes["modal-nl"]} show={showModal} onHide={handleClose}>
                <Modal.Header className={classes["modal-header"]} closeButton>
                        <div className={classes["img-holder"]}>
                            <img
                                src={check}
                                className="d-inline-block align-top w-100 img-fluid"
                                alt="React"
                            />
                        </div>
                        <div className={classes["text-holder"]}>
                            <h3>Uspešna prijava!</h3>
                            <p>Hvala Vam za prijavu na newsletter.</p>
                        </div>
                </Modal.Header>
            </Modal>
    );
}

export default NewsletterModal;