import React from "react";
import Homepage from './Pages/Homepage';
import NavbarMenu from './Components/NavbarMenu';
import ProductPage from "./Pages/ProductPage";
import { Navigate, Route, Routes } from "react-router";
import Footer from './Components/Footer';
import './App.css';

function App() {

  return (
    <div className="App">
      <NavbarMenu></NavbarMenu>
      <Routes>
        <Route path='' exact element={<Homepage/>}/>
        <Route path='/proizvod/:productSlug'  element={<ProductPage/>}/>
        <Route path='*' element = {<div>Stranica ne postoji</div>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
