import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../img/logo/logo.png';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import classes from './Footer.module.css';

const Footer = () => {
    return (
        <div className={classes["footer-holder"]}>
            <Container fluid>
                <Row className={classes["footer-top"]}>
                    <Col xl={4} lg={4} md={6} sm={12} xs={12}>
                        <div className={classes["footer-company"]}>
                            <div className={classes["image-holder"]}>
                                <img
                                    src={logo}
                                    className="d-inline-block align-top w-100"
                                    alt="React"
                                />
                            </div>
                            <div className={classes["text-holder"]}>
                                <p>Aleksandra Savića 42</p>
                                <p>32000, Čačak, Srbija</p>
                                <a href="#">Prikaži na mapi</a>
                                <ul className={classes.social}>
                                    <li className={classes.instaicon}>
                                        <a href="#">
                                            <FontAwesomeIcon className={classes.insta} icon={faInstagram} />
                                        </a>
                                    </li>
                                    <li className={classes.facebookicon}>
                                        <a href="#">
                                            <FontAwesomeIcon className={classes.fb} icon={faFacebookF} />
                                        </a>
                                    </li>
                                    <li className={classes.linkedinicon}>  
                                        <a href="#">
                                            <FontAwesomeIcon className={classes.linkedin} icon={faLinkedinIn} />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={6} sm={6} xs={6} className={classes["footer-info-holder"]}>
                        <div className={classes["info-holder"]}>
                            <p className={classes.title}>Informacije</p>
                            <div className={classes.underline}></div>
                            <p><span>+381 64 613 94 68</span></p>
                            <p className={classes.gray}>Pon-Pet: 09.00-20.00 </p>
                            <p className={classes.gray}>Sub: 11.00-16.00</p>
                            <p>medigrafca@gmail.com</p>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                        <div className={classes["links-holder"]}>
                         <p className={classes.title}>Delatnost</p>
                         <div className={classes.underline}></div>
                            <ul>
                                <li><a href="#">Brendiranje Objekata</a></li>
                                <li><a href="#">Brendiranje Enterijera</a></li>
                                <li><a href="#">Izrada Reklama</a></li>
                                <li><a href="#">Izrada Zastava</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                        <div className={classes["links-holder"]}>
                        <p className={classes.title}>Delatnost</p>
                        <div className={classes.underline}></div>
                            <ul>
                                <li><a href="#">Auto Grafika</a></li>
                                <li><a href="#">Specijalne Folije Za Automobile</a></li>
                                <li><a href="#">Reklamni Materijal</a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xl={2} lg={2} md={4} sm={6} xs={6}>
                        <div className={classes["links-holder"]}>
                        <p className={classes.title}>Linkovi</p>
                        <div className={classes.underline}></div>
                            <ul>
                                <li><a href="#">O nama</a></li>
                                <li><a href="#">Reference i radovi</a></li>
                                <li><a href="#">Novosti</a></li>
                                <li><a href="#">Kontakt</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row className={classes["footer-bottom"]}>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className={classes.copyright}>
                        <p>© {new Date().getFullYear()}<span> Medigraf </span>  | Sva prava zadržana.</p>
                    </Col>
                    <Col xl={6} lg={6} md={6} sm={12} xs={12} className={classes.croonus}>
                        <p>Powered by <span> Croonus</span>.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default Footer;