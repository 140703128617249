import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table'
import { Specification } from "../Helpers/const";
import classes from './ProductSpecification.module.css';

const ProductSpecification = () => {
    return (
        <Container fluid>
            <div className={classes["specification-holder"]}>
                {Specification.map(item=>
                <div key={Math.random()}>
                    <div className={classes["spec-title"]} >
                        <h1>34.470.10 BIZZ - Poslovni ranac</h1>
                    </div>
                    <div className={classes.lager}>
                        <div className={classes.square}></div>
                        <p className={classes.lager}>Na stanju</p>
                    </div>
                    <div className={classes["spec-price"]}>
                        <button type="button">Pošaljite zahtev za cenu</button>
                    </div>
                    <div className={classes["spec-info"]}>
                        <Row>
                            <Col xl={3}>
                                <p>OPŠTE INFORMACIJE</p>
                                <div className={classes.line}></div>
                            </Col>
                            <Col>
                            <Table className={classes.table}>
                                <tbody>
                                    <tr>
                                        <td>Šifra:</td>
                                        <td>{item["spec-code"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Model:</td>
                                        <td>{item["spec-model"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Opis:</td>
                                        <td>{item["spec-desc"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Brend:</td>
                                        <td>{item["spec-brand"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Boja:</td>
                                        <td>{item["spec-color"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Dimenzije:</td>
                                        <td>{item["spec-dimensions"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Pakovanje:</td>
                                        <td>{item["spec-package"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Materijal:</td>
                                        <td>{item["spec-material"]}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}>
                                <p>LOGISTIČKI PODACI</p>
                                <div className={classes.line}></div>
                            </Col>
                            <Col>
                            <Table className={classes.table}>
                                <tbody>
                                    <tr>
                                        <td>Količina u kutiji:</td>
                                        <td>{item["spec-quantity"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Dimenzije transportne kutije (D x Š x V):</td>
                                        <td>{item["spec-box-dimensions"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Bruto težina kutije: </td>
                                        <td>{item["spec-box-weight"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Zapremina kutije:</td>
                                        <td>{item["spec-box-volume"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Zemlja porekla:</td>
                                        <td>{item["spec-made-in"]}</td>
                                    </tr>
                                    <tr>
                                        <td>Carinska tarifa: </td>
                                        <td>{item["spec-tariff"]}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            </Col>
                        </Row>
                    </div>
                </div>
                )}
            </div>
        </Container>
    )
};

export default ProductSpecification;